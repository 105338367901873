import Component from 'vue-class-component';
import { sitePrecenseService } from '@/main';
import Vue from 'vue';
import to from 'await-to-js';
import moment from 'moment/min/moment.min.js';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import PageRender from '../../models/PageRender';
import SitePresence from '../../models/SitePresence';
import { $router } from '@/router';
import { sitesModule } from '@/store/modules/site';

@Component
export default class GuestsPage extends PageRender {
    public isLoading: boolean = true;
    public currentGuests: SitePresence[] = [];

    public gridColumns = [
        { field: 'bookingId', cell: this.renderBookingLink, title: 'Boeking' },
        { field: 'arrivalDate', title: 'Aankomstdag' },
        { field: 'departureDate', title: 'Vertrekdag' },
        { field: 'mainBooker', title: 'Hoofdboeker', cell: this.renderMaindbookerLink },
        { field: 'numberOfTravellers', title: 'Aantal gasten' },
    ];

    public exportGridColumns = [
        { field: 'bookingId', title: 'Boeking' },
        { field: 'arrivalDate', title: 'Aankomstdag', format: '{0:dd-MM-yyyy}' },
        { field: 'departureDate', title: 'Vertrekdag', format: '{0:dd-MM-yyyy}' },
        { field: 'mainBooker.emailAddress', title: 'Hoofdboeker' },
        { field: 'numberOfTravellers', title: 'Aantal gasten' },
    ];

    public skip: number = 0;
    public take: number = 25;

    public get total() {
        return this.currentGuests ? this.currentGuests.length : 0;
    }

    public get site() {
        return sitesModule.activeSite;
    }

    public get currentGuestsData() {
        return this.currentGuests.slice(this.skip, this.take + this.skip);
    }

    public async mounted() {
        const self = this;
        await sitesModule.fetchSitesIfNeeded();

        const [error, response] = await to(sitePrecenseService.getSitePrecense(this.site.siteId));
        if (error) {
            this.showFailedResponse('Mislukt om de huidige bezoeker op te halen.', error);
            return false;
        }

        response.data.forEach((sitePresence) => {
            self.currentGuests.push({
                bookingId: sitePresence.bookingId,
                mainBooker: sitePresence.mainBooker,
                arrivalDate: moment(sitePresence.arrivalDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                departureDate: moment(sitePresence.departureDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                numberOfTravellers: self.totalNumberOfGuests(sitePresence.travelGroup),
            } as SitePresence);
        });

        this.isLoading = false;
    }

    public totalNumberOfGuests(travelGroup) {
        let guestCount = 0;
        travelGroup.forEach((group: any) => {
            guestCount += group.amountOfTravellers;
        });

        return guestCount;
    }

    public renderBookingLink(h: any, a, row) {
        const route = $router.resolve({
            name: 'booking',
            params: { siteId: this.siteId.toString(), siteKey: this.siteKey, bookingKey: row.dataItem.bookingId },
        });

        const props = { text: 'bk-' + row.dataItem.bookingId, url: route.href };
        return h(Vue.component('grid-router-link'), { props });
    }

    public renderMaindbookerLink(h: any, a, row) {
        const mainBooker = row.dataItem.mainBooker;
        const route = $router.resolve({
            name: 'booker',
            params: { siteId: this.siteId.toString(), siteKey: this.siteKey, bookerId: row.dataItem.mainBooker.mainBookerId },
        });

        const props = { text: `${mainBooker.firstName} ${mainBooker.insertion ? mainBooker.insertion : ''} ${mainBooker.lastName}`, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    public pageChangeHandler(event) {
        this.skip = event.page.skip;
        this.take = event.page.take;
    }

    public async exportExcel() {
        saveExcel({
            data: this.currentGuests,
            fileName: `Huidige-bezoekers-${moment().format('YYYY-MM-DD HH:mm:ii')}`,
            columns: this.exportGridColumns,
        });
    }
}

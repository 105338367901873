import Component from 'vue-class-component';
import Booking from '../../models/Booking';
import Booker from '../../models/Booker';
import { dictionaryHelper, dateHelper } from '@/main';
import Vue from 'vue';
import { filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import BookingBasePage from '../../models/BookingBasePage';
import moment from 'moment/min/moment.min.js';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import { $router } from '@/router';
import { orderBy } from '@progress/kendo-data-query';
import BookingState, { BookingStateEnum } from '@/models/BookingState';

@Component
export default class BookingsPage extends BookingBasePage {
    public bookingStates: any[] = null;
    public gridColumns = [
        { field: 'bookingIdString', cell: this.renderBookingLink, title: 'Boeking', width: 125, filterable: false },
        { field: 'accommodationsString', cell: this.renderAccommodationSelect, title: 'Accommodaties', filterable: false },
        { field: 'bookingStateName', title: 'Status', filterCell: this.renderStatusFilter, width: 200 },
        { field: 'arrivalDate', title: 'Aankomstdag', filterable: false, format: '{0:dd-MM-yyyy}', width: 200 },
        { field: 'departureDate', title: 'Vertrekdag', filterable: false, format: '{0:dd-MM-yyyy}', width: 200 },
        { field: 'mainBookerName', cell: this.renderMaindbookerLink, title: 'Hoofdboeker', width: 200, filterable: false },
        { field: 'guests', title: 'Aantal gasten', filterCell: 'number-filter', width: 125, filterable: false },
        { field: 'source', title: 'Bron', width: 150, filterable: false },
        { field: 'channel', title: 'Kanaal', width: 150, filterable: false },
        { title: 'Info', cell: this.renderSpecialNeeds, width: 80, filterable: false },
    ];

    public exportGridColumns = [
        { field: 'bookingId', title: 'Boeking' },
        { field: 'accommodations[0].name', title: 'Accommodatie (eerste)' },
        { field: 'bookingStateName', title: 'Status' },
        { field: 'arrivalDate', title: 'Aankomstdag', format: '{0:dd-MM-yyyy}' },
        { field: 'departureDate', title: 'Vertrekdag', format: '{0:dd-MM-yyyy}' },
        { field: 'mainBooker.emailAddress', title: 'Hoofdboeker' },
        { field: 'guests', title: 'Aantal gasten' },
    ];

    public filter: CompositeFilterDescriptor = {
        logic: 'and',
        filters: [],
    };

    public sort: any[] = [{ field: 'bookingId', dir: 'desc' }];
    public skip: number = 0;
    public take: number = 25;
    public filterByPeriod: boolean = true;

    public $refs!: {
        bookingGrid: any;
    };

    private bookings: Booking[] = [] as Booking[];
    private bookingsGridData: any = [];
    private allBookingsGridData: any = [];
    private filteredBookingState: number = null;

    public async mounted() {
        await this.initBoookingBase();
    }

    public redirectToAccoSearchPage() {
        this.$router.push({ name: 'accommodation-search' });
    }

    public pageChangeHandler(event) {
        this.skip = event.page.skip;
        this.take = event.page.take;
    }

    public get total() {
        const filteredItems = filterBy(this.bookingsGridData, this.filter) as [];
        return filteredItems ? filteredItems.length : 0;
    }

    public bookingsGridDataFiltered(): Booking[] {
        const items = filterBy(this.bookingsGridData, this.filter) as [];
        const ordered = orderBy(items, this.sort);
        return ordered.slice(this.skip, this.take + this.skip);
    }

    public allBookingsGridDataFiltered(): Booking[] {
        return filterBy(this.allBookingsGridData, this.filter) as [];
    }

    public filterChange(ev) {
        this.filter = ev.filter;
    }

    public update(dataItems) {
        this.bookingsGridData = dataItems;
    }

    public async periodChanged(dates) {
        this.isLoading = true;
        if (!this.places || this.places.length === 0) {
            await this.getPlaces();
        }
        this.bookings = await this.filterBookings(dates.fromFilterMonth, dates.toFilterMonth);
        this.prepareBookingGrid();
        this.isLoading = false;
    }

    public async dateChanged(dates) {
        this.isLoading = true;
        if (!this.places || this.places.length === 0) {
            await this.getPlaces();
        }
        this.bookings = await this.filterBookings(null, null, dates.from, dates.to);
        this.prepareBookingGrid();
        this.isLoading = false;
    }

    public async exportExcel() {
        saveExcel({
            data: this.allBookingsGridDataFiltered(),
            fileName: `Boeking-overzicht-${moment().format('YYYY-MM-DD HH:mm')}.xlsx`,
            columns: this.exportGridColumns,
        });
    }

    private renderAccommodationSelect(h: any, a, row) {
        const self = this;
        const accommodations = row.dataItem.accommodations;
        if (accommodations.length === 0) {
            return h('td', `geen accommodaties`);
        }

        if (accommodations.length === 1) {
            return h('td', `${accommodations[0].name}`);
        }

        const props = {
            text: `${accommodations.length} accommodaties`,
            items: accommodations,
            route: 'acco',
            textField: 'name',
            valueField: 'placeId',
            callback(item) {
                self.$router.push({
                    name: 'acco',
                    params: { siteId: this.siteId.toString(), siteKey: self.siteKey, placeKey: item.placeId },
                });
            },
        };

        return h(Vue.component('grid-select'), { props });
    }

    private renderBookingLink(h: any, a, row) {
        const route = $router.resolve({
            name: 'booking',
            params: { siteId: this.siteId.toString(), siteKey: this.siteKey, bookingKey: row.dataItem.bookingId },
        });

        const props = { text: row.dataItem.bookingIdString, url: route.href };
        return h(Vue.component('grid-router-link'), { props });
    }

    private renderMaindbookerLink(h: any, a, row) {
        const mainBooker = row.dataItem.mainBooker;
        const props = { text: null, url: null };

        if (mainBooker.mainBookerId) {
            const route = $router.resolve({
                name: 'booker',
                params: { siteId: this.siteId.toString(), siteKey: this.siteKey, bookerId: mainBooker.mainBookerId },
            });

            props.text = `${mainBooker.firstName} ${mainBooker.insertion ? mainBooker.insertion : ''} ${mainBooker.lastName}`;
            props.url = route.href;
        }

        return h(Vue.component('grid-router-link'), { props });
    }

    private async filterBookings(startMonth?: string, endMonth?: string, startDate?: string, endDate?: string) {
        return this.getBookings(this.filteredBookingState, startMonth, endMonth, startDate, endDate);
    }

    private prepareBookingGrid() {
        this.bookingsGridData = [];
        this.bookings.forEach((bookingC: Booking) => {
            const booking = new Booking(bookingC);
            const bookingPlaces = this.places.filter((place) => booking.places.map((p) => p.placeId).indexOf(place.placeId) > -1);

            this.bookingsGridData.push({
                bookingId: booking.bookingId,
                bookingIdString: `bk-${booking.bookingId}`,
                accommodations: bookingPlaces,
                bookingState: booking.bookingState,
                bookingStateName: dictionaryHelper.get('BookingStates')[booking.bookingState],
                arrivalDate: dateHelper.toDate(booking.arrivalDate),
                departureDate: dateHelper.toDate(booking.departureDate),
                mainBooker: booking.mainBooker,
                mainBookerName: new Booker(booking.mainBooker).name(),
                guests: booking.totalNumberOfGuests(),
                source: booking.source,
                channel: booking.channel,
                additionalNote: booking.additionalNote,
                internalMemo: booking.internalMemo,
            });
        });

        this.allBookingsGridData = this.bookingsGridData;
    }

    private renderStatusFilter(h, a, row) {
        const props = {
            options: BookingState.getStatussen(),
            filter: this.filter,
            field: 'bookingStateName',
            defaultSelected: [
                BookingState.getStatus(BookingStateEnum.Reserved),
                BookingState.getStatus(BookingStateEnum.Confirmed),
                BookingState.getStatus(BookingStateEnum.AffiliateReserved),
                BookingState.getStatus(BookingStateEnum.CheckedIn),
                BookingState.getStatus(BookingStateEnum.CheckedOut),
            ],
        };

        return h(Vue.component('grid-filter-multi'), { props });
    }

    private renderSpecialNeeds(h: any, a, row) {
        const booking = row.dataItem;
        const props = { booking };
        return h(Vue.component('grid-special-needs'), { props });
    }
}

import Site from './Site';
import { siteService, propertyTypeService, featureService, bookerService, bookingService, invoiceService, dictionaryHelper } from '@/main';
import to from 'await-to-js';
import PlaceType from './PlaceType';
import PropertyType from './PropertyType';
import { AxiosResponse } from 'axios';
import PageRender from './PageRender';
import Feature from './Feature';
import Place from './Place';
import BasePage from './BasePage';
import Booker from './Booker';
import { Invoice } from './Invoice';
import InvoiceBasePage from './InvoiceBasePage';
import { sitesModule } from '@/store/modules/site';

export default class BookingBasePage extends InvoiceBasePage {
    public bookingKey: string = '';
    public isLoading: boolean = true;
    public bookers: Booker[] = [] as Booker[];
    public places: Place[] = [] as Place[];
    public bookingStates: any[] = null;
    public invoices: Invoice[] = [] as Invoice[];

    public async initBoookingBase() {
        this.bookingKey = this.$route.params.bookingKey;
        await sitesModule.fetchSitesIfNeeded();

        [this.places, this.bookingStates] = await Promise.all([this.getPlaces(), this.getBookingStates()]);
        this.bookingStates.forEach((state) => {
            state.text = dictionaryHelper.get('BookingStates')[state.bookingStateId];
        });
    }

    public async getInvoices(bookingKey?: string) {
        let key = bookingKey;
        if (!key) {
            key = this.bookingKey;
        }

        const [err, response] = await to<AxiosResponse<Invoice[]>>(invoiceService.getInvoices(this.site.siteId, null, null, null, key));
        if (err) {
            this.showError('Mislukt om facturen op te halen');
            return (this.invoices = []);
        }
        return (this.invoices = response.data);
    }

    public async getBookers() {
        const [err, response] = await to<AxiosResponse<Booker[]>>(bookerService.getBookers(this.site.siteId));
        if (err) {
            this.showError('Mislukt om hoofdboekers op te halen');
        }
        return (this.bookers = response.data);
    }

    public async getPlaces() {
        const [err, placesResponse] = await to<AxiosResponse<Place[]>>(siteService.getPlaces(this.siteKey));
        if (err) {
            this.showError('Mislukt om accommodaties op te halen');
        }
        return (this.places = placesResponse.data);
    }

    public async getBookingStates() {
        return [
            { bookingStateId: 1, name: 'Reserved' },
            { bookingStateId: 2, name: 'AffiliateReserved' },
            { bookingStateId: 3, name: 'Confirmed' },
            { bookingStateId: 4, name: 'Rejected' },
            { bookingStateId: 6, name: 'CheckedIn' },
            { bookingStateId: 7, name: 'CheckedOut' },
            { bookingStateId: 8, name: 'Cancelled' },
        ];
    }
}

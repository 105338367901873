export enum BookingStateEnum {
    Unknown = 'Unknown',
    Reserved = 'Reserved',
    AffiliateReserved = 'AffiliateReserved',
    Confirmed = 'Confirmed',
    Rejected = 'Rejected',
    // Paid = 'Paid',
    CheckedIn = 'CheckedIn',
    CheckedOut = 'CheckedOut',
    Canceled = 'Canceled',
}

export default class BookingState {
    public static getStatus(status: BookingStateEnum = BookingStateEnum.Unknown): string {
        if (this.status[BookingStateEnum[status]]) {
            return this.status[BookingStateEnum[status]];
        }

        return this.status[0];
    }

    public static getStatussen() {
        return [
            { value: BookingState.status.Reserved, text: BookingState.status.Reserved },
            // { value: BookingState.status.AffiliateReserved, test: BookingState.status.AffiliateReserved },
            { value: BookingState.status.Confirmed, text: BookingState.status.Confirmed },
            { value: BookingState.status.Rejected, text: BookingState.status.Rejected },
            { value: BookingState.status.CheckedIn, text: BookingState.status.CheckedIn },
            { value: BookingState.status.CheckedOut, text: BookingState.status.CheckedOut },
            { value: BookingState.status.Canceled, text: BookingState.status.Canceled },
        ];
    }

    private static status = {
        Unknown: 'Onbekend',
        Reserved: 'Gereserveerd',
        AffiliateReserved: 'AffiliateGereserveerd',
        Confirmed: 'Bevestigd',
        Rejected: 'Afgewezen',
        // Paid: 'Betaald',
        CheckedIn: 'Ingecheckt',
        CheckedOut: 'Uitgecheckt',
        Canceled: 'Geannuleerd',
    };
}

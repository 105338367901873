var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('prd-grid-layout',{attrs:{"title":"Boekingen"}},[_c('div',{staticClass:"prd-grid-wrapper show-scroll"},[_c('prd-grid-toolbar',{attrs:{"align-center":""}},[_c('template',{slot:"left"},[_c('generic-search',{attrs:{"data-items":_vm.allBookingsGridDataFiltered(),"properties":['bookingId', 'bookingIdString', 'mainBookerName', 'emailAddress', 'firstName', 'lastName', 'name']},on:{"finished-search":_vm.update}}),_c('b-dd',{attrs:{"no-caret":""}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"icon-filter"}),_vm._v(" "+_vm._s(_vm.filterByPeriod ? 'Periode' : 'Datum')+" "),_c('i',{staticClass:"icon-chevron-down"})]),_c('b-dd-item',{on:{"click":function($event){_vm.filterByPeriod = true}}},[_vm._v("Periode")]),_c('b-dd-item',{on:{"click":function($event){_vm.filterByPeriod = false}}},[_vm._v("Datum")])],2),(_vm.filterByPeriod)?_c('grid-period-range-filter',{attrs:{"no-end-month":true},on:{"filter-change":_vm.periodChanged,"filter-input":_vm.periodChanged}}):_c('grid-date-range',{on:{"filter-change":_vm.dateChanged}})],1),_c('template',{slot:"right"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.redirectToAccoSearchPage()}}},[_vm._v(" Nieuwe boeking ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.exportExcel}},[_vm._v(" Exporteer overzicht ")])],1)],2),(!_vm.isLoading)?_c('grid',{ref:"bookingGrid",staticStyle:{"height":"auto"},attrs:{"data-items":_vm.bookingsGridDataFiltered(),"filterable":true,"filter":_vm.filter,"pageable":true,"take":_vm.take,"sort":_vm.sort,"skip":_vm.skip,"total":_vm.total,"sortable":true,"columns":_vm.gridColumns},on:{"pagechange":_vm.pageChangeHandler,"filterchange":_vm.filterChange},scopedSlots:_vm._u([{key:"filterStatusTemplate",fn:function(ref){
var props = ref.props;
var methods = ref.methods;
return [_c('b-input-group',[_c('select',{staticClass:"form-control",domProps:{"value":props.value},on:{"input":function (ev) {
                                methods.change({ operator: 'contains', field: props.field, value: ev.target.value, syntheticEvent: ev });
                            }}},[_c('option',{domProps:{"value":null}},[_vm._v("Selecteer status")]),_vm._l((_vm.bookingStates),function(state){return _c('option',{key:state.bookingStateId,domProps:{"value":state.text}},[_vm._v(" "+_vm._s(state.text)+" ")])})],2),_c('b-input-group-append',[_c('b-button',{attrs:{"type":"button"},on:{"click":function (ev) {
                                    methods.change({ operator: '', field: '', value: '', syntheticEvent: ev });
                                }}},[_c('span',{staticClass:"k-icon k-i-filter-clear"})])],1)],1)]}}],null,false,3777879398)},[_c('grid-norecords',[_vm._v(" Geen resultaten ")])],1):_c('spinner')],1),_c('debug-component',{attrs:{"object":_vm.bookingsGridData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Component from 'vue-class-component';
import Booking from '../../models/Booking';
import { bookingService, dictionaryHelper, homeOwnerService, invoiceService } from '@/main';
import Vue from 'vue';
import to from 'await-to-js';
import BookingBasePage from '../../models/BookingBasePage';
import moment from 'moment/min/moment.min.js';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import HomeOwner from '../../models/HomeOwner';
import { $router } from '@/router';
import { BModal } from 'bootstrap-vue';
import Departure from '@/models/CheckInOut/Departure';
import Arrival from '@/models/CheckInOut/Arrival';
import CheckInOut from '@/models/CheckInOut/CheckInOut';

@Component
export default class ChecksPage extends BookingBasePage {
    public isLoading: boolean = true;
    public checkIns: Arrival[] = [];
    public checkOuts: Departure[] = [];
    public homeOwners: HomeOwner[] = [] as HomeOwner[];
    public updateGridsCheckIn: number = 0;
    public updateGridsCheckOut: number = 100;
    public selectedRange: any = { value: 0, text: 'Vandaag' };
    public get arrivalDate() {
        return moment()
            .add('d', this.selectedRange.value)
            .format('YYYY-MM-DD');
    }
    public gridColumns = [
        { field: 'bookingId', cell: this.renderBookingLink, title: 'Boeking', width: 110 },
        { field: 'accommodation', title: 'Accommodaties', cell: this.renderAccommodationSelect },
        { field: 'arrivalDateFormatted', title: 'Aankomstdag', width: 140 },
        { field: 'departureDateFormatted', title: 'Vertrekdag', width: 125 },
        { field: 'mainBooker', title: 'Hoofdboeker', cell: this.renderMaindbookerLink, width: 140 },
        { field: 'numberOfGuests', title: 'Aantal gasten', width: 145 },
        { title: 'Eigenaarsboeking?', cell: this.renderOwnerOrNot, width: 180, filterable: false },
        { title: 'Te voldoen', cell: this.renderPaymentStatus, width: 130, filterable: false },
        { title: 'Bijzonderheden?', cell: this.renderSpecialNeeds, width: 160, filterable: false },
        { title: 'Wissel', field: 'exchange', width: 100 },
        { title: 'Acties', cell: this.renderActions, filterable: false, width: 95 },
    ];
    public exportGridColumns = [
        { field: 'bookingId', title: 'Boeking' },
        { field: 'accommodationsText', title: 'Accommodaties' },
        { field: 'arrivalDateFormatted', title: 'Aankomstdag' },
        { field: 'departureDateFormatted', title: 'Vertrekdag' },
        { field: 'mainBooker.emailAddress', title: 'Hoofdboeker' },
        { field: 'numberOfGuests', title: 'Aantal gasten' },
        { field: 'exchange', title: 'Wissel' },
    ];
    public booking: Booking = null;
    public $refs!: {
        checkInModal: BModal;
        checkOutModal: BModal;
    };

    public get total() {
        return this.checkIns ? this.checkOuts.length : 0;
    }

    public async mounted() {
        this.isLoading = true;
        await this.loadCheckInAndOuts();
        await this.getHomeOwners();
        this.isLoading = false;
    }

    public async loadCheckInAndOuts() {
        this.checkIns = await bookingService.getArrivals(this.siteId, this.arrivalDate);
        this.checkOuts = await bookingService.getDepartures(this.siteId, this.arrivalDate);

        this.mapCheckIns();
        this.mapCheckOuts();
    }

    public renderBookingLink(h: any, a, row) {
        const route = $router.resolve({
            name: 'booking',
            params: { siteId: this.siteId.toString(), siteKey: this.siteKey, bookingKey: row.dataItem.bookingId },
        });

        const props = { text: 'bk-' + row.dataItem.bookingId, url: route.href };
        return h(Vue.component('grid-router-link'), { props });
    }

    public renderMaindbookerLink(h: any, a, row) {
        const mainBooker = row.dataItem.mainBooker;
        if (mainBooker) {
            const route = $router.resolve({
                name: 'booker',
                params: { siteId: this.siteId.toString(), siteKey: this.siteKey, bookerId: mainBooker.mainBookerId.toString() },
            });

            const props = {
                text: `${mainBooker.firstName} ${mainBooker.insertion ? mainBooker.insertion : ''} ${mainBooker.lastName}`,
                url: route.href,
            };

            return h(Vue.component('grid-router-link'), { props });
        }

        return h('td');
    }

    public async exportExcelCheckIns() {
        saveExcel({
            data: this.checkIns,
            fileName: `Check-ins-${moment().format('YYYY-MM-DD HH:mm')}.xlsx`,
            columns: this.exportGridColumns,
        });
    }

    public async exportExcelCheckOuts() {
        saveExcel({
            data: this.checkOuts,
            fileName: `Check-outs-${moment().format('YYYY-MM-DD HH:mm')}.xlsx`,
            columns: this.exportGridColumns,
        });
    }

    public renderSpecialNeeds(h: any, a, row) {
        const booking = row.dataItem;
        const props = { booking };

        return h(Vue.component('grid-special-needs'), { props });
    }

    public renderPaymentStatus(h: any, _, row) {
        const booking = row.dataItem;
        return h('td', {}, [Vue.filter('formatPrice')(booking.openInvoiceAmount)]);
    }

    public renderOwnerOrNot(h: any, a, row) {
        const mainBooker = row.dataItem.mainBooker;
        const accos = row.dataItem.accommodations;
        let homeOwner = null;
        if (mainBooker) {
            homeOwner = this.homeOwners.find((x) => x.emailAddress === mainBooker.emailAddress);
        }

        if (!homeOwner || !accos || !accos.length || accos[0].placeId) {
            return h('td', {}, ['Nee']);
        }

        const place = this.places.find((p) => p.placeId === accos[0].placeId);

        if (place.homeOwnerId === homeOwner.homeOwnerId) {
            return h('td', {}, ['Ja']);
        }

        return h('td', {}, ['Nee']);
    }

    public async sendIdealLinkForBooking(booking: Booking) {
        this.bookingKey = booking.bookingId.toString();
        const invoices = await this.getInvoices();
        const invoice = invoices.find((i) => i.status < 6);
        this.sendIdealLink(invoice);
    }

    public async sendIdealLink(invoice: any) {
        const invoiceId = invoice.invoiceId;
        this.showPending('Ideallink genereren en versturen..');

        const [err, response] = await to(invoiceService.sendIdealLinkMail(invoiceId));
        if (err) {
            return this.clearAndShowError('Betaal link genereren en versturen is mislukt', err);
        }

        invoice.status = dictionaryHelper.get('InvoiceStates')[2];

        this.clearAndShowSuccess('Betaal link succesvol verstuurd.');
        this.editMode = false;
    }

    public async showCheckInModal(item) {
        this.booking = item;
        Vue.nextTick(() => {
            this.$refs.checkInModal.show();
        });
    }

    public async showCheckoutModal(item) {
        this.booking = item;

        Vue.nextTick(() => {
            this.$refs.checkOutModal.show();
        });
    }

    public get periods() {
        return [
            { value: 0, text: 'Vandaag' },
            { value: 1, text: 'Morgen' },
            { value: 2, text: 'Overmorgen' },
            { value: 7, text: 'Komende week' },
            { value: 14, text: 'Komende twee weken' },
            { value: 28, text: 'Komende vier weken' },
        ];
    }

    public async onPeriodSelected() {
        await this.$nextTick();
        this.isLoading = true;
        await this.loadCheckInAndOuts();
        this.isLoading = false;
    }

    public async checkIn() {
        this.showPending('Boeking inchecken..');
        const [err] = await to(bookingService.checkIn(this.booking.bookingId, this.site.siteId));
        if (err) {
            return this.clearAndShowError('Boeking inchecken mislukt.', err);
        }

        this.clearAndShowSuccess('Boeking ingecheckt.');
        this.$refs.checkInModal.hide();
        this.editMode = false;

        this.checkIns = this.checkIns.filter((x) => x.bookingId !== this.booking.bookingId);
        this.updateGridsCheckIn++;
    }

    public async checkOut() {
        this.showPending('Boeking uitchecken..');

        const [err] = await to(bookingService.checkOut(this.booking.bookingId, this.site.siteId));
        if (err) {
            return this.clearAndShowError('Boeking uitchecken mislukt.', err);
        }

        this.clearAndShowSuccess('Boeking uitgecheckt.');
        this.$refs.checkOutModal.hide();
        this.editMode = false;

        this.checkOuts = this.checkOuts.filter((x) => x.bookingId !== this.booking.bookingId);
        this.updateGridsCheckOut++;
    }

    private async mapCheckIns() {
        this.checkIns.forEach((check: Arrival) => {
            check.exchange = this.isArrivalExchange(check) ? 'Ja' : 'Nee';
            check.arrivalDateFormatted = moment(check.arrivalDate).format('DD-MM-YYYY');
            check.departureDateFormatted = moment(check.departureDate).format('DD-MM-YYYY');
            check.accommodationsText = this.getAccommodationText(check);
        });
    }

    private async mapCheckOuts() {
        this.checkOuts.forEach((check: Departure) => {
            check.exchange = this.isDepartureExchange(check) ? 'Ja' : 'Nee';
            check.arrivalDateFormatted = moment(check.arrivalDate).format('DD-MM-YYYY');
            check.departureDateFormatted = moment(check.departureDate).format('DD-MM-YYYY');
            check.accommodationsText = this.getAccommodationText(check);
        });
    }

    private async getHomeOwners() {
        const [err, response] = await to(homeOwnerService.getHomeOwners());
        if (err) {
            return this.showError('Mislukt om eigenaren op te halen');
        }

        return (this.homeOwners = response.data);
    }

    private renderAccommodationSelect(h: any, a, row) {
        const self = this;
        const accommodations = row.dataItem.places;
        if (accommodations.length === 0) {
            return h('td', `geen accommodaties`);
        }

        if (accommodations.length === 1) {
            return h('td', `${accommodations[0].placeName} - plek: ${accommodations[0].lotNumber}`);
        }

        accommodations.forEach((element) => {
            element.name = element.placeName;
        });

        const props = {
            text: `${accommodations.length} accommodaties`,
            items: accommodations,
            route: 'acco',
            textField: 'placeName',
            valueField: 'placeId',
            callback(item) {
                self.$router.push({
                    name: 'acco',
                    params: { siteId: this.siteId.toString(), siteKey: self.siteKey, placeKey: item.placeId },
                });
            },
        };

        return h(Vue.component('grid-select'), { props });
    }

    private getAccommodationText(arrival: CheckInOut) {
        const accommodations = arrival.places;
        const names = accommodations.map((acco) => {
            return `${acco.placeName} - plek: ${acco.lotNumber}`;
        });
        return names.join(', ');
    }

    private renderActions(h: any, a, row: { dataItem: Arrival }) {
        const status = row.dataItem.bookingStatus;
        const actions = [];

        if (status === 'Confirmed' && !row.dataItem.openInvoiceAmount) {
            actions.push({ title: 'Check-in', function: this.showCheckInModal });
        }

        if (row.dataItem.openInvoiceAmount) {
            actions.push({
                title: 'Bekijk (on)betaalde facturen',
                function: () => {
                    this.$router.push({
                        name: 'booking',
                        params: { siteId: this.siteId.toString(), siteKey: this.siteKey, bookingKey: row.dataItem.bookingId.toString() },
                    });
                },
            });
        }

        if (status === 'CheckedIn') {
            actions.push({ title: 'Check-out', function: this.showCheckoutModal });
        }

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    private isDepartureExchange(departure: Departure) {
        const hasCheckIn = this.checkIns.find((arrival: Arrival) => {
            const arrivalDate = new Date(arrival.arrivalDate);
            const departureDate = new Date(departure.departureDate);
            const sameDate = arrivalDate.getDate() === departureDate.getDate() &&
                arrivalDate.getMonth() === departureDate.getMonth();

            return sameDate && this.samePlace(arrival, departure);
        });

        return !!hasCheckIn;
    }

    private isArrivalExchange(arrival: Arrival) {
        const hasCheckIn = this.checkOuts.find((departure: Departure) => {
            const arrivalDate = new Date(arrival.arrivalDate);
            const departureDate = new Date(departure.departureDate);
            const sameDate = arrivalDate.getDate() === departureDate.getDate() &&
                arrivalDate.getMonth() === departureDate.getMonth();

            return sameDate && this.samePlace(arrival, departure);
        });

        return !!hasCheckIn;
    }

    private samePlace(arrival: Arrival, departure: Departure) {
        const aPlaceIds = arrival.places.map((place) => place.placeId);
        const dPlaceIds = departure.places.map((place) => place.placeId);

        return aPlaceIds.filter((placeId: number) => dPlaceIds.includes(placeId)).length > 0;
    }
}

import Component from 'vue-class-component';
import { siteService, roleHelper } from '@/main';
import Place from '../../models/Place';
import Vue from 'vue';
import BasePage from '../../models/BasePage';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import moment from 'moment/min/moment.min.js';
import { $router } from '@/router';
import to from 'await-to-js';
import GridWrapperSearch from '@prd/shared-ui/dist/services/components/Grid/models/GridWrapperSearch';

@Component
export default class AccommodationsOverview extends BasePage {
    public places: Place[] = [];
    public allPlaces: Place[] = [];
    public placesGridColumns = [
        { field: 'lotNumber', cell: this.renderLotNumber, title: 'Pleknummer', width: '120px' },
        { field: 'name', cell: this.renderAccomodationName, title: 'Naam' },
        { field: 'capacity', title: 'Capaciteit', width: '100px' },
        { field: 'description', title: 'Omschrijving', width: '350px' },
        { field: 'layout', title: 'Layout', width: '350px' },
        { field: '', cell: this.renderActions, title: 'Actions', width: '150px' },
    ];
    public exportPlacesGridColumns = [
        { field: 'lotNumber', title: 'Pleknummer', width: '100px' },
        { field: 'name', title: 'Naam' },
        { field: 'capacity', title: 'Capaciteit', width: '100px' },
        { field: 'description', title: 'Omschrijving', width: '250px' },
        { field: 'layout', title: 'Layout', width: '150px' },
    ];
    public search = new GridWrapperSearch({ properties: ['lotNumber', 'name', 'description', 'layout'] });
    public updatedGrid: number = 1;

    public async created() {
        this.initBase();
        this.allPlaces = this.places = (await siteService.getPlaces(this.siteKey)).data;
        this.isLoading = false;
    }

    public goToNewAccommodationPage() {
        $router.push({ name: 'acco', params: { placeKey: 'aanmaken' } });
    }

    public renderActions(h, a, row: any): any {
        const actions = [
            { title: 'Bekijk', function: this.goToAcco },
            { title: 'Bewerk', function: this.editAcco },
            { title: 'Verwijder', function: this.deleteAcco },
        ];
        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    public goToAcco(item: Place): any {
        $router.push({ name: 'acco', params: { placeKey: item.placeId.toString() } });
    }
    public editAcco(item: Place): any {
        $router.push({ name: 'acco', params: { placeKey: item.placeId.toString(), mode: 'edit' } });
    }

    public async deleteAcco(item) {
        this.showPending('Accommodatie verwijderen..');

        const [err, response] = await to(siteService.deletePlace(item.placeId));
        if (err) {
            return this.clearAndShowError(`Verwijderen mislukt. <br/> ${err}`);
        }

        this.clearAndShowSuccess('Accommodatie verwijderd.');
    }

    public async exportExcel() {
        saveExcel({
            data: this.places,
            fileName: `Accommodaties-${moment().format('YYYY-MM-DD HH:mm:ii')}`,
            columns: this.exportPlacesGridColumns,
        });
    }

    public update(dataItems) {
        this.places = dataItems;

        this.updatedGrid++;
    }

    public renderLotNumber(h, tdElement, row) {
        const item = row.dataItem;
        const placeId = item.placeId ? item.placeId.toString() : '';
        const route = $router.resolve({
            name: 'acco',
            params: { placeKey: placeId, siteId: this.siteId.toString(), siteKey: this.siteKey },
        });
        const props = { text: item.lotNumber, url: route.href };

        if (roleHelper.isRecreapiSiteAdmin() || roleHelper.isRecreapiSystemAdmin()) {
            return h(Vue.component('grid-router-link'), { props });
        }

        return h('td', item.name);
    }

    public renderAccomodationName(h, tdElement, row) {
        const item = row.dataItem;
        const placeId = item.placeId ? item.placeId.toString() : '';
        const route = $router.resolve({
            name: 'acco',
            params: { placeKey: placeId, siteKey: this.siteKey },
        });
        const props = { text: item.name, url: route.href };

        if (roleHelper.isRecreapiSiteAdmin() || roleHelper.isRecreapiSystemAdmin()) {
            return h(Vue.component('grid-router-link'), { props });
        }

        return h('td', item.name);
    }
}
